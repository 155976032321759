import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Gallery from '../components/Gallery';

const BrandingStyle = () => (
  <Layout>
    <Helmet
      title="Velgama - Firminis stilius"
      meta={[
        { name: 'description', content: 'Logotipų ir suvenyrų kūrimas bei gamyba' },
      ]}
    />

    <div id="main">
      <Banner h1="Firminis stilius" paragraph="Logotipai, blankai, vizitinės kortelės..." bannerType="branding-style-banner" />
      <section id="one" className="spotlights">
        <StaticQuery
          query={galleryQuery}
          render={(data) => <Gallery data={data} />}
        />
      </section>
    </div>
  </Layout>
);

const galleryQuery = graphql`
query brandingStyleImages {
    allFile(
      filter: { sourceInstanceName: { eq: "images-branding-style" } },
      sort: {order: ASC, fields: name}
      ) {
      edges {
        node {
          dir
          name
          childImageSharp{
            fluid (maxWidth: 496, maxHeight: 348){
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
`;

export default BrandingStyle;
